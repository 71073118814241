<template>
    <div class="status-tag">
        <span v-text="status"></span>
    </div>
</template>

<script>
  export default {
    name: 'StatusTag',

    props: [
      'status',
    ]
  }
</script>

<style scoped lang="scss">
    .status-tag {
        @apply h-6 inline-flex items-center px-3 rounded rounded-full text-sm text-white;
        transition: all 300ms ease-in-out;

        span {
            @apply font-semibold inline-block uppercase leading-none;
        }
    }
</style>
