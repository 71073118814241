<template>
    <div class="preview-panel">
        <div class="content">
            <PlacementPreview v-for="size in sizes"
                              :key="size.size.id"
                              :model="size.manifest"
                              :size="size.size"
            ></PlacementPreview>
        </div>
    </div>
</template>

<script>
  import PlacementPreview from '@/components/PlacementPreview'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Preview',
    components: {
      PlacementPreview,
    },

    data () {
      return {}
    },

    methods: {},

    computed: {

      ...mapGetters({
        creative: 'data/creative',
        sizes: 'data/sizes'
      }),

    }
  }
</script>

<style lang="scss">
    .preview-panel {
        @apply w-full h-full;

        //display: grid;
        //grid-template-rows: 60px auto;
        //grid-template-areas: "header"
        //                     "content";

        .content {
            @apply flex flex-wrap items-center justify-center mx-auto mt-10;

            height: max-content;

            .placement-preview {
                @apply mx-6 my-6;
            }
        }

    }
</style>
