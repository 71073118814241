import Vue from 'vue'
import { maxSatisfying } from 'semver'

export default {
  namespaced: true,

  state: () => ({
    apiToken: null,
    // client: null,
    id: null,
    payload: null,
    token: null,
    creative: null,
    sizes: {},
    statics: {},
    template: {},
    templates: [],
  }),

  mutations: {

    setApiToken (state, apiToken) {
      state.apiToken = apiToken
    },

    // setClient (state, client) {
    //   state.client = client
    // },

    setCreative (state, creative) {
      state.creative = creative
    },

    setId (state, id) {
      state.id = id
    },

    setPayload (state, payload) {
      state.payload = payload
    },

    setSize (state, data) {
      Vue.set(state.sizes, data.size.id, data)
    },

    setStatics (state, data) {
      Vue.set(state.statics, data.sizeId, data.statics)
    },

    setTemplate (state, template) {
      state.template = template
    },

    setTemplates (state, templates) {
      Vue.set(state, 'templates', templates)
    },

    setToken (state, token) {
      state.token = token
    }

  },

  actions: {

    async fetchManifest ({ commit, getters, rootGetters }) {
      console.log('called fetchManifest')
      let path = `${rootGetters['client/api_root']}/creative/${getters.id}`

      let response = await fetch(path, getters.requestHeaders).catch(console.log)

      commit('setCreative', await response.json())
    },

    async fetchSizeManifests ({ commit, dispatch, getters, rootGetters }) {
      for (let size of getters.creative.sizes) {
        let path = `${rootGetters['client/api_root']}/creative/${getters.id}/size/${size.id}`

        let response = await fetch(path, getters.requestHeaders)
          .catch(console.log)

        // If API request fails, skip this size because it's not in review
        if (!response.ok) continue

        let manifest = await response.json()

        commit('setSize', {
          size,
          manifest
        })

        dispatch('fetchStaticsManifest', size.id)
      }
    },

    async fetchStaticsManifest ({ commit, getters }, sizeId) {
      const path = `${getters.staticsRoot(sizeId)}/manifest.json`

      let response = await fetch(path).catch(console.log)

      commit('setStatics', {
        sizeId: sizeId,
        statics: await response.json()
      })
    },

    /**
     * Fetch the template manifest from the API, or fall back to the old static manifest
     * @param commit
     * @param getters
     * @param rootGetters
     * @returns {Promise<void>}
     */
    async fetchTemplatesManifest ({ commit, getters, rootGetters }) {
      let manifest = []

      try {
        // Fetch the new manifest from the API
        const path = `${rootGetters['client/api_root']}/template`

        const response = await fetch(path, getters.requestHeaders)

        manifest = await response.json()
      } catch (e) {
        // If the API request fails, fall back to the old manifest
        const manifestPath = `${rootGetters['schema/placementRoot']}/${rootGetters['client/client'].id}/templates.json`

        let response = await fetch(manifestPath + '?' + Date.now()).catch(console.log)

        manifest = await response.json()
      } finally {
        commit('setTemplates', manifest)
      }
    },

    async loadTemplate ({ commit, dispatch, getters, rootGetters }) {
      console.log('Called loadTemplate')

      // Fetch the template manifest
      await dispatch('fetchTemplatesManifest')

      const creative = getters.creative
      const templateDefinition = getters.templates.find(template => template.default)

      let selectedTemplate = {
        client: rootGetters['client/client'].id,
        template: templateDefinition.id,
        version: templateDefinition.defaultVersion,
      }

      // If the creative already has a template, use that
      if (creative.template) {
        const { template } = getters.creative

        selectedTemplate = template
      }

      // if isDevMode, override default or stored template with latest prerelease version
      if (rootGetters.isDevMode) {
        // We're in dev mode, so override the template version with latest prerelease version
        selectedTemplate.version = maxSatisfying(templateDefinition.versions, '*', { includePrerelease: true })
      }

      // Set the template
      commit('setTemplate', selectedTemplate)
    },

    async parseToken ({ commit, dispatch, getters }, rawToken = null) {

      if (rawToken && getters.token !== rawToken) {

        commit('setToken', rawToken)

        let [client, id, signature] = atob(rawToken).split('.')

        commit('setId', id)

        commit('setApiToken', rebuildToken(getters.id, signature))

        // Work around for Renault specific token
        try {
          // Try to fetch the clients
          await dispatch('client/fetchClients', null, { root: true })

        } catch (e) {
          // If the API request fails, build a token for the specific client key
          commit('setApiToken', rebuildToken(getters.id, signature, `${client}-key`))

          await dispatch('client/fetchClients', null, { root: true })
        }

        await dispatch('client/setClient', client, { root: true })
      }

    },
  },

  getters: {

    apiToken: state => state.apiToken,

    // client: state => state.client,

    creative: state => state.creative,

    creativeDisplayName: state => {
      if (state.creative) {
        return state.creative.name.replace(/_/g, ' ')
      }

      return ''
    },

    id: state => state.id,

    payload: state => state.payload,

    requestHeaders: (state, getters) => {
      return {
        headers: {
          Authorization: `Bearer ${getters.apiToken}`
        }
      }
    },

    sizes: state => state.sizes,

    statics: state => sizeId => {
      let manifest = state.statics[sizeId]

      if (manifest) {
        return state.statics[sizeId].images.map(image => `${image}?${manifest.timestamp}`)
      }
    },

    staticsRoot: (state, getters, rootState, rootGetters) => sizeId => {
      let s3_root = /*process.env.NODE_ENV === 'development' ? 'http://localhost:4569/bigred-dd5-compose-bucket' :*/ rootGetters['client/s3_root']

      return `${s3_root}/creatives/${getters.id}/sizes/${sizeId}/statics`
    },

    templates: state => state.templates,

    templateName: state => {
      const { client, template, version } = state.template

      return [client, template, version].join('-')
    },

    token: state => state.token,

  }
}

function rebuildToken (id, signature, key = 'composer-key') {
  let header = JSON.stringify({
    'alg': 'HS256',
    'kid': key,
  })

  let payload = JSON.stringify({
    id: id,
    sub: 'com.bigredgroup.dd5-preview',
    iss: 'com.bigredgroup.dd5-compose',
  })

  let encodedHeader = btoa(header).replace(/=/g, '')
  let encodedPayload = btoa(payload).replace(/=/g, '')

  return `${encodedHeader}.${encodedPayload}.${signature}`
}
