<template>
    <div class="placement-preview"
         :class="sizeSchema.properties.name"
    >
        <iframe ref="placement"
                class="placement-frame" frameborder="0"
                :src="placementSrc"
                :height="sizeSchema.properties.dimensions.y"
                :width="sizeSchema.properties.dimensions.x"
                scrolling="no"
        ></iframe>

        <div class="controls">
            <button class="btn" @click="refresh">
                <PlayIcon size="1.5x"></PlayIcon>
            </button>
        </div>
    </div>
</template>

<script>
  import { PlayIcon } from 'vue-feather-icons'
  import { transformData } from '@dynamicdisplay/composer-transform'
  // import { transformData } from '@/config/transformData'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PlacementPreview',

    components: {
      PlayIcon
    },

    props: [
      'model',
      'size',
    ],

    data () {
      return {}
    },

    mounted () {
      this.$refs.placement.addEventListener('load', this.hookEvents)
    },

    computed: {

      ...mapGetters({
        client: 'client/id',
        clientSchema: 'schema/client',
        creativeId: 'data/id',
        getSizeSchema: 'schema/size',
        templateName: 'data/templateName',
        placementRoot: 'schema/placementRoot',
        token: 'data/apiToken',
      }),

      placementJson () {
        return JSON.stringify(transformData(this.model.placement, this.model.frames, this.clientSchema.output.placement, this.clientSchema.output.frame))
      },

      placementSrc () {
        if (encodeURIComponent(this.placementJson).length > (7 * 1024)) {
          return `${this.placementRoot}/${this.client}/${this.templateName}/${this.sizeSchema.properties.name}/index.html?creative=${this.creativeId}&size=${this.size.id}&token=${this.token}`
        }

        return `${this.placementRoot}/${this.client}/${this.templateName}/${this.sizeSchema.properties.name}/index.html?data=${encodeURIComponent(this.placementJson)}`
      },

      sizeSchema () {
        return this.getSizeSchema(this.size.name)
      },
    },

    methods: {
      refresh () {
        this.$refs.placement.src = this.placementSrc
      },

      hookEvents () {
        this.$refs.placement.contentWindow.document.body.addEventListener('composer:exit', event => {
          console.log('composer:exit', event.detail)
          window.open(event.detail.exitLink)
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .placement-preview {
        @apply bg-white shadow-lg;

        .btn {
            @apply bg-transparent border-0 px-0 rounded-none text-gray-800;
        }
    }

    .placement-frame {
        margin: theme('spacing.2');
        transition: all 250ms ease-in-out;
        overflow: hidden;
    }

    .controls {
        @apply flex justify-center;
    }
</style>
