import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Preview from '@/views/Preview'
import StaticPreview from '@/views/StaticPreview'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/static/:token',
    name: 'static-flats',
    component: StaticPreview,
    meta: {
      label: 'Static flats',
    }
  },
  {
    path: '/preview/:token',
    name: 'preview',
    component: Preview,
    meta: {
      label: 'Animated',
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: process.env.NODE_ENV === 'test' ? 'hash' : 'history',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
