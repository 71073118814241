<template>
  <div class="static-preview-panel">
    <div class="size" v-for="size in filteredSizes"
         :key="size.size.id">
      <h1 v-text="size.size.name"></h1>
      <div class="frames">
        <img class="frame" alt=""
             v-for="(frame, index) of getStatics(size.size.id)"
             :key="index"
             :src="`${getStaticsRoot(size.size.id)}/${frame}`"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StaticPreview',

  props: [],

  components: {},

  data () {
    return {}
  },

  computed: {

    ...mapGetters({
      creative: 'data/creative',
      sizes: 'data/sizes',
      getStatics: 'data/statics',
      getStaticsRoot: 'data/staticsRoot',
    }),

    filteredSizes () {
      return Object.values(this.sizes).filter(size => this.getStatics(size.size.id))
    }

  },
}
</script>

<style lang="scss" scoped>
.static-preview-panel {
  @apply px-8;
}

.size {
  @apply max-w-screen-2xl mx-auto py-8;

  h1 {
    @apply font-semibold mb-8 text-3xl;
  }

  .frames {
    @apply flex flex-wrap;

    .frame {
      @apply m-4;
    }
  }
}
</style>
