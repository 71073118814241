var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout",attrs:{"id":"app"}},[_c('header',{staticClass:"header"},[_c('img',{staticClass:"inline",attrs:{"src":require("./assets/composer-logo.svg"),"alt":"Composer"}}),_c('div',{staticClass:"flex inline items-center"},[(_vm.isDevMode)?_c('div',{staticClass:"flex gap-2 items-center mx-4 rounded-full text-sm text-white"},[_c('AlertTriangleIcon',{attrs:{"size":"20"}}),_c('span',{staticClass:"font-semibold"},[_vm._v("Development mode: Previewing untested placements")])],1):_vm._e(),_c('img',{staticClass:"inline h-8",attrs:{"src":require("./assets/bigred-logo.svg"),"alt":"Big Red"}})])]),_c('nav',[_c('div',{staticClass:"label"},[_c('span',{staticClass:"creative-title",domProps:{"textContent":_vm._s(_vm.name)}}),(_vm.name)?_c('StatusTag',{attrs:{"status":"Review"}}):_vm._e()],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"nav-buttons flex px-2.5"},[_c('router-link',{attrs:{"to":{ name: 'preview', params: { token: _vm.$route.params.token }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var route = ref.route;
return [_c('button',{staticClass:"btn",class:{ active: isActive },on:{"click":navigate}},[_c('FilmIcon',{attrs:{"size":"20"}}),_vm._v(" "+_vm._s(route.meta.label)+" ")],1)]}}])}),_c('router-link',{attrs:{"to":{ name: 'static-flats', params: { token: _vm.$route.params.token }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var route = ref.route;
return [_c('button',{staticClass:"btn",class:{ active: isActive },on:{"click":navigate}},[_c('ImageIcon',{attrs:{"size":"20"}}),_vm._v(" "+_vm._s(route.meta.label)+" ")],1)]}}])})],1),_c('a',{staticClass:"btn btn-icon",on:{"click":_vm.copyLink}},[_c('LinkIcon',{attrs:{"size":"20"}})],1)])]),_c('router-view',{staticClass:"content"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }