import Vue from 'vue'

/**
 * Client Store
 */
export default {
  namespaced: true,

  state: () => ({

    client: null,

    clients: [],

  }),

  mutations: {

    setClients (state, clients) {
      Vue.set(state, 'clients', clients)
    },

    setClient (state, client) {
      Vue.set(state, 'client', client)
    },

  },

  actions: {

    async fetchClients ({ commit, rootGetters }) {
      const path = `${process.env.VUE_APP_COMPOSER_API_ROOT}/clients`

      const response = await fetch(path, rootGetters['data/requestHeaders'])

      if (response.ok) {
        const clients = await response.json()

        if (clients.length) {
          commit('setClients', clients)
        }
      } else {
        throw 'Could not fetch clients'
      }
    },

    async setClient ({ state, commit }, clientName) {
      const client = state.clients.find(client => client.id === clientName)

      commit('setClient', client)

      // await dispatch('template/fetchTemplateManifest', client.id, { root: true })
    },

  },

  getters: {

    api_root: state => state.client.api_root,

    client: state => state.client,

    id: state => state.client.id,

    s3_root: state => state.client.s3_root,

  }

}
