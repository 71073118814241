<template>
  <div id="app" class="app-layout">
    <header class="header">
      <img src="./assets/composer-logo.svg" alt="Composer" class="inline">
      <div class="flex inline items-center">
          <div class="flex gap-2 items-center mx-4 rounded-full text-sm text-white" v-if="isDevMode">
            <AlertTriangleIcon size="20"></AlertTriangleIcon>
            <span class="font-semibold">Development mode: Previewing untested placements</span>
          </div>
        <img src="./assets/bigred-logo.svg" alt="Big Red" class="inline h-8">
      </div>
    </header>

    <nav>
      <div class="label">
        <span class="creative-title" v-text="name"></span>
        <StatusTag status="Review" v-if="name"></StatusTag>
      </div>

      <div class="flex">
        <div class="nav-buttons flex px-2.5">
          <router-link :to="{ name: 'preview', params: { token: $route.params.token }}" custom v-slot="{ navigate, isActive, route }">
            <button class="btn"
                    :class="{ active: isActive }"
                    @click="navigate"
            >
              <FilmIcon size="20"></FilmIcon>
              {{ route.meta.label }}
            </button>
          </router-link>

          <router-link :to="{ name: 'static-flats', params: { token: $route.params.token }}" custom v-slot="{ navigate, isActive, route }">
            <button class="btn"
                    :class="{ active: isActive }"
                    @click="navigate"
            >
              <ImageIcon size="20"></ImageIcon>
              {{ route.meta.label }}
            </button>
          </router-link>
        </div>

        <a class="btn btn-icon" @click="copyLink">
          <LinkIcon size="20"></LinkIcon>
        </a>
      </div>
    </nav>

    <router-view class="content"/>
  </div>
</template>

<script>
import { AlertTriangleIcon, FilmIcon, ImageIcon, LinkIcon } from 'vue-feather-icons'
import StatusTag from '@/components/StatusTag'
import { mapGetters } from 'vuex'
import copy from 'copy-text-to-clipboard'

export default {
  name: 'App',
  components: {
    AlertTriangleIcon,
    FilmIcon,
    ImageIcon,
    LinkIcon,
    StatusTag,
  },

  async created () {
    if (this.$route.params.token) {
      // Parse Token and set Client
      await this.$store.dispatch('data/parseToken', this.$route.params.token)

      // Fetch Creative from the API
      await this.$store.dispatch('data/fetchManifest')

      // if route query string contains dev=true, set dev mode
      if (Object.hasOwn(this.$route.query, 'dev') && this.$route.query.dev === 'true') {
        await this.$store.dispatch('setDevMode')
      }

      await this.$store.dispatch('data/loadTemplate')

      await this.$store.dispatch('schema/fetchSchemas')

      await this.$store.dispatch('data/fetchSizeManifests')
    }
  },

  methods: {

    copyLink () {
      const url = new URL(window.location.href)

      url.searchParams.delete('dev')

      copy(url.toString())
    },

  },

  computed: {

    ...mapGetters({
      isDevMode: 'isDevMode',
      name: 'data/creativeDisplayName',
    }),

  },

}
</script>

<style lang="scss">
.app-layout {
  @apply bg-gray-100 h-full min-h-screen;
  display: grid;
  grid-template-rows: 80px 60px auto;
  grid-template-areas:
            "header"
            "navbar"
            "content";
}

.header {
  @apply bg-brand-red flex items-center justify-between text-white px-5 py-3 h-20;
  grid-area: header;
}

nav {
  @apply block bg-white flex items-center justify-between px-8 py-3 shadow;
  grid-area: navbar;

  .label {
    @apply flex items-center;
  }

  .creative-title {
    @apply font-semibold text-xl;
  }

  .status-tag {
    @apply bg-brand-green mx-4;
  }
}

.content {
  grid-area: content;
}

footer {
  @apply flex justify-center items-center flex-grow h-8 text-center mb-8;
  grid-area: footer;
}

.btn {
  @apply bg-gray-100 h-10 p-2.5 text-gray-800;

  &.btn-icon {
    @apply rounded-full;
  }

  &:hover {
    @apply bg-gray-300;
  }
}

.nav-buttons {
  .btn {
    @apply flex font-semibold px-5 text-sm;
    box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.20), inset 0 0 0 1px theme('colors.gray.200');

    &:hover {
      box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.20), inset 0 0 0 1px theme('colors.gray.300');
    }

    &:first-child {
      @apply rounded-l-full;
    }

    &:last-child {
      @apply rounded-r-full;
    }

    .feather {
      @apply flex-shrink-0 mr-2.5;
    }

    &.active {
      @apply bg-blue-600 shadow-none text-white;
    }
  }
}

//.btn {
//  @apply border-2 border-brand-blue cursor-pointer flex font-semibold h-10 items-center px-6 rounded rounded-full text-brand-blue text-sm;
//  transition: all 100ms ease-in-out;
//
//  .feather {
//    @apply mr-2;
//  }
//
//  &:hover {
//    @apply bg-brand-blue text-white;
//  }
//
//  &:active {
//    transform: translateY(1px);
//  }
//}
</style>
