import Vue from 'vue'

const PLACEMENT_ROOT = 'https://review.bigredgroup.com/templates'

export default {
  namespaced: true,

  state: () => ({
    client: {},
    sizes: {},
  }),

  mutations: {

    setClient (state, clientSchema) {
      state.client = clientSchema
    },

    setSize (state, schema) {
      let key = schema.properties.label
      Vue.set(state.sizes, key, schema)
    }
  },

  actions: {

    /**
     * Fetch the JSON Schema for the current client
     * @param commit
     * @param rootGetters
     * @return {Promise<void>}
     */
    async fetchClientSchema ({ commit, rootGetters }) {
      const path = `${PLACEMENT_ROOT}/${rootGetters['client/id']}/${rootGetters['data/templateName']}/client.json`

      let response = await fetch(path).catch(console.log)

      commit('setClient', await response.json())
    },

    /**
     * Fetch the JSON Schemas for client and sizes
     * @param dispatch
     * @param getters
     * @return {Promise<void>}
     */
    async fetchSchemas ({ dispatch, getters }) {
      await dispatch('fetchClientSchema')

      for (let size of getters.client.sizes) {
        await dispatch('fetchSizeSchema', size)
      }
    },

    /**
     * Fetch a JSON Schema for the specified size
     * @param commit
     * @param rootGetters
     * @param size
     * @return {Promise<void>}
     */
    async fetchSizeSchema ({ commit, rootGetters }, size) {
      let path = `${PLACEMENT_ROOT}/${rootGetters['client/id']}/${rootGetters['data/templateName']}/${size}/${size}.json`

      let response = await fetch(path).catch(console.log)

      commit('setSize', await response.json())
    }

  },

  getters: {

    // api_root: state => state.client.api_root,

    client: state => state.client,

    // s3_root: state => state.client.s3_root,

    placementRoot: () => PLACEMENT_ROOT,

    size: state => size => state.sizes[size],

    sizes: state => state.sizes,

  }
}
