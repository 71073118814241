import Vue from 'vue'
import Vuex from 'vuex'
import client from '@/store/client'
import data from '@/store/data'
import schema from '@/store/schema'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    client,
    data,
    schema,
  },

  state: {
    devMode: false,
  },

  mutations: {

    setDevMode(state, value) {
      state.devMode = value
    },

  },

  actions: {

    async setDevMode ({ commit }) {
      commit('setDevMode', true)
    },

  },

  getters: {
    isDevMode: state => state.devMode,
  },
})
